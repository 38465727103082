<template>
    <MobileMenuContainer @toggle-menu="toggleMobileMenu">
        <template #logo>
            <img
                class="w-8"
                src="@/assets/images/custom/logo.png"
            />
        </template>

        <template #menuIcon>
            <font-awesome-icon
                :icon="['fal', 'bars']"
                class="text-white w-5 h-5"
            />
        </template>

        <template #menuList>
            <transition @enter="transition.enter" @leave="transition.leave">
                <ul
                    v-if="activeMobileMenu"
                    class="border-t border-theme-29 py-5"
                >
                    <li
                        v-for="(menu, menuKey) in menus"
                        :key="menu + menuKey"
                    >
                        <MenuDivider v-if="menu == 'devider'" />

                        <MobileMenuItem
                            v-else
                            :menu="menu"
                            :key="menu + menuKey"
                            @navigate="navigate"
                        >
                            <template #icon>
                                <font-awesome-icon
                                    v-if="menu.icon"
                                    :icon="['fal', menu.icon]"
                                />
                            </template>

                            <template #title>
                                {{ menu.title }}
                            </template>

                            <template #dropdownIcon>
                                <font-awesome-icon
                                    :icon="['fal', 'chevron-down']"
                                    class="w-5 h-5"
                                />
                            </template>

                            <template #subMenu>
                                <transition
                                    @enter="transition.enter"
                                    @leave="transition.leave"
                                >
                                    <ul
                                        v-if="
                                            menu.subMenu && menu.activeDropdown
                                        "
                                    >
                                        <li
                                            v-for="(
                                                subMenu, subMenuKey
                                            ) in menu.subMenu"
                                            :key="subMenu + subMenuKey"
                                        >
                                            <MobileMenuItem
                                                :menu="subMenu"
                                                @navigate="navigate"
                                            >
                                                <template #icon>
                                                    <font-awesome-icon
                                                        v-if="subMenu.icon"
                                                        :icon="[
                                                            'fal',
                                                            subMenu.icon,
                                                        ]"
                                                        class="
                                                            text-white
                                                            w-5
                                                            h-5
                                                        "
                                                    />
                                                </template>

                                                <template #title>
                                                    {{ subMenu.title }}
                                                </template>

                                                <template #dropdownIcon>
                                                    <font-awesome-icon
                                                        :icon="[
                                                            'fal',
                                                            'chevron-down',
                                                        ]"
                                                        class="w-5 h-5"
                                                    />
                                                </template>

                                                <template #subMenu>
                                                    <transition
                                                        @enter="transition.enter"
                                                        @leave="transition.leave"
                                                    >
                                                        <ul
                                                            v-if="subMenu.subMenu && subMenu.activeDropdown"
                                                        >
                                                            <template
                                                                v-for="(
                                                                    lastSubMenu,
                                                                    lastSubMenuKey
                                                                ) in subMenu.subMenu"
                                                                :key="lastSubMenu + lastSubMenuKey"
                                                            >
                                                                <MobileMenuItem
                                                                    :menu="lastSubMenu"
                                                                    @navigate="navigate"
                                                                >
                                                                    <template #icon>
                                                                        <font-awesome-icon
                                                                            v-if="lastSubMenu.icon"
                                                                            :icon="['fal', lastSubMenu.icon,]"
                                                                            class="
                                                                                text-white
                                                                                w-5
                                                                                h-5
                                                                            "
                                                                        />
                                                                    </template>

                                                                    <template
                                                                        #title
                                                                    >
                                                                        {{
                                                                            lastSubMenu.title
                                                                        }}
                                                                    </template>
                                                                </MobileMenuItem>
                                                            </template>
                                                        </ul>
                                                    </transition>
                                                </template>
                                            </MobileMenuItem>
                                        </li>
                                    </ul>
                                </transition>
                            </template>
                        </MobileMenuItem>
                    </li>
                </ul>
            </transition>
        </template>
    </MobileMenuContainer>
</template>

<script>
import { ref } from 'vue';
import {
	MobileMenuContainer,
	MenuDivider,
	MobileMenuItem,
	Utilities
} from '@success-apps/sa-vue-tailwind';
export default {
	components: {
		MobileMenuContainer,
		MenuDivider,
		MobileMenuItem
	},
	props: {
		menus: {
			type: Object,
			required: true
		}
	},
	setup (props, { emit }) {
		const { transition } = Utilities;

		const activeMobileMenu = ref(false);
		const toggleMobileMenu = () => {
			activeMobileMenu.value = !activeMobileMenu.value;
		};

		const navigate = (menu) => {
			emit('navigate', menu);
		};
		return {
			transition,
			toggleMobileMenu,
			activeMobileMenu,
			navigate
		};
	}
};
</script>
