import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import FontAwesomeIcon from '@/fontAwesome';
import i18n from '@/services/i18n';
import { makeServer } from '@/services/mirage';

import { createMetaManager } from 'vue-meta';

// SASS Theme
import '@/assets/scss/app.scss';

if (process.env.NODE_ENV === 'development-api-mock') {
	makeServer();
}

const metaManager = createMetaManager();

const app = createApp(App)
	.use(store)
	.use(router)
	.use(FontAwesomeIcon)
	.use(metaManager)
	.use(i18n);

app.mount('#app');
