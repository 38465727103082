<template>
	<div class="intro-x dropdown w-8 h-8">
		<Dropdown
			width-class="w-52"
			position="right"
			content-class="
                absolute
                p-2
                absolute mt-2
                focus:outline-none
                dropdown-menu__content
                box
                dark:bg-dark-3
                inset-auto
                shadow-2xl
            "
		>
			<template #button>
				<img
					class="
						w-8
						h-8
						rounded-full
						overflow-hidden
						shadow-lg
						image-fit
						zoom-in
					"
					src="@/assets/images/logo.svg"
				/>
			</template>

			<template #content>
				<DropdownMenu> Profile </DropdownMenu>

				<DropdownMenuDivider />

				<DropdownMenu @click="logout" :disabled="loggingOut">
					<span>
						{{ loggingOut ? 'Logging out...' : 'Logout' }}
					</span>
				</DropdownMenu>
			</template>
		</Dropdown>
	</div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { Dropdown, DropdownMenu, DropdownMenuDivider } from '@success-apps/sa-vue-tailwind';

export default {
	components: {
		Dropdown,
		DropdownMenu,
		DropdownMenuDivider
	},
	setup () {
		const store = useStore();
		const loggingOut = ref(false);

		const logout = async () => {
			loggingOut.value = true;

			store.dispatch('auth/logout').then((response) => {
				loggingOut.value = false;
			});
		};

		return {
			loggingOut,
			logout
		};
	}
};
</script>
