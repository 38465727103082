<template>
    <SideMenuContainer>
        <template #logo>
            <router-link
                :to="'/'"
                tag="a"
                class="intro-x flex items-center justify-center pt-4"
            >
                <img
                    src="@/assets/images/custom/brand-logo.png"
                    class="object-contain h-14"
                />
            </router-link>
        </template>

        <template #menu>
            <!-- BEGIN: Menu -->
            <transition @enter="transition.enter" @leave="transition.leave">
                <ul>
                    <li
                        v-for="(menu, menuKey) in menus"
                        :key="menu + menuKey"
                    >
                        <MenuDivider v-if="menu == 'divider'"/>

                        <SideMenuItem
                            v-else
                            :menu="menu"
                            @navigate="navigate"
                        >
                            <template #icon>
                                <font-awesome-icon
                                    v-if="menu.icon"
                                    :icon="['fal', menu.icon]"
                                />
                            </template>

                            <template #title>
                                {{ menu.title }}
                            </template>

                            <template #dropdownIcon>
                                <font-awesome-icon
                                    :icon="['fal', 'chevron-down']"
                                    class="w-5 h-5"
                                />
                            </template>

                            <template #subMenu>
                                <transition @enter="transition.enter" @leave="transition.leave">
                                    <ul v-if="menu.subMenu && menu.activeDropdown">
                                        <li
                                            v-for="(subMenu, subMenuKey) in menu.subMenu"
                                            :key="subMenu + subMenuKey"
                                        >
                                            <SideMenuItem
                                                :menu="subMenu"
                                                @navigate="navigate"
                                            >
                                                <template #icon>
                                                    <font-awesome-icon
                                                        v-if="subMenu.icon"
                                                        :icon="['fal', subMenu.icon]"
                                                        class="text-white w-5 h-5"
                                                    />
                                                </template>

                                                <template #title>
                                                    {{ subMenu.title }}
                                                </template>

                                                <template #dropdownIcon>
                                                    <font-awesome-icon
                                                        :icon="['fal', 'chevron-down']"
                                                        class="w-5 h-5"
                                                    />
                                                </template>

                                                <template #subMenu>
                                                    <transition @enter="transition.enter" @leave="transition.leave">
                                                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                                                            <template
                                                                v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                                                                :key="lastSubMenu + lastSubMenuKey"
                                                            >
                                                                <SideMenuItem
                                                                    :menu="lastSubMenu"
                                                                    @navigate="navigate"
                                                                >
                                                                    <template #icon>
                                                                        <font-awesome-icon
                                                                            v-if="lastSubMenu.icon"
                                                                            :icon="['fal', lastSubMenu.icon]"
                                                                            class="text-white w-5 h-5"
                                                                        />
                                                                    </template>

                                                                    <template #title>
                                                                        {{ lastSubMenu.title }}
                                                                    </template>
                                                                </SideMenuItem>
                                                            </template>
                                                        </ul>
                                                    </transition>
                                                </template>
                                            </SideMenuItem>
                                        </li>
                                    </ul>
                                </transition>
                            </template>
                        </SideMenuItem>
                    </li>
                </ul>
            </transition>
            <!-- END: Menu -->
        </template>
    </SideMenuContainer>
</template>

<script>
import { ref } from 'vue';
import {
	SideMenuContainer,
	MenuDivider,
	SideMenuItem,
	Utilities
} from '@success-apps/sa-vue-tailwind';
export default {
	components: {
		SideMenuContainer,
		MenuDivider,
		SideMenuItem
	},
	props: {
		menus: {
			type: Object,
			required: true
		}
	},
	setup (props, { emit }) {
		const { transition } = Utilities;

		const activeMobileMenu = ref(false);
		const toggleMobileMenu = () => {
			activeMobileMenu.value = !activeMobileMenu.value;
		};

		const navigate = (menu) => {
			emit('navigate', menu);
		};
		return {
			transition,
			toggleMobileMenu,
			activeMobileMenu,
			navigate
		};
	}
};
</script>
