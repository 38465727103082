<template>
	<DarkModeSwitcher
		:dark-mode="isDarkMode"
		@onSwitch="switchDarkMode"
		v-if="false"
	/>

	<MobileMenu :menus="formattedMenu" @navigate="navigate"/>

    <div class="flex admin-container">
        <!-- BEGIN: Side Menu -->
		<SideMenu :menus="formattedMenu" @navigate="navigate"/>
        <!-- END: Side Menu -->

        <!-- BEGIN: Content -->
		<ContentContainer>
			<!-- BEGIN: Top Bar -->
			<TopBar/>
			<!-- END: Top Bar -->

            <router-view />
		</ContentContainer>
        <!-- END: Content -->
    </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useMeta } from 'vue-meta';
import MobileMenu from './main-layout/MobileMenu.vue';
import SideMenu from './main-layout/SideMenu.vue';
import TopBar from './main-layout/top-bar/index.vue';
import {
	DarkModeSwitcher,
	ContentContainer,
	Utilities
} from '@success-apps/sa-vue-tailwind';

export default {
	components: {
		DarkModeSwitcher,
		ContentContainer,
		MobileMenu,
		SideMenu,
		TopBar
	},
	setup () {
		useMeta({
			title: 'Clarity'
		});

		const store = useStore();

		const { transition, nestedMenu } = Utilities;

		const route = useRoute();
		const router = useRouter();

		// should be from store
		const isDarkMode = ref(false);
		const switchDarkMode = () => {
			isDarkMode.value = isDarkMode.value !== true;

			const mainHtml = document.querySelector('html');
			isDarkMode.value
				? mainHtml.setAttribute('class', 'dark')
				: mainHtml.removeAttribute('class', 'dark');
		};

		const currentRoute = computed(() => {
			return {
				name: route.name,
				path: route.path
			};
		});

		const menus = ref([
			{
				icon: 'home',
				name: 'dashboard',
				title: 'Dashboard'
			},
			{
				icon: 'home',
				name: 'user',
				title: 'User',
				subMenu: [
					{
						icon: 'home',
						name: 'user',
						title: 'Active'
					}
				]
			}
		]);

		const formattedMenu = ref([]);
		const sideMenu = computed(() =>
			nestedMenu(menus.value, currentRoute.value)
		);

		watch(
			computed(() => currentRoute.value),
			() => {
				formattedMenu.value = sideMenu.value;
			}
		);

		onMounted(() => {
			formattedMenu.value = sideMenu.value;

			/** This will check the authenticated */
			store.dispatch('auth/getUserProfile');
		});

		const navigate = (menu) => {
			if (menu.subMenu) {
				menu.activeDropdown = !menu.activeDropdown;
				return;
			}

			router.push({ name: menu.name });
		};
		/** End Menu */

		return {
			isDarkMode,
			switchDarkMode,
			currentRoute,
			menus,
			navigate,
			transition,
			formattedMenu
		};
	}
};
</script>
