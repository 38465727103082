<template>
	<div class="container sm:px-10">
		<div class="block xl:grid grid-cols-2 gap-4">
			<!-- BEGIN: Login Info -->
			<div class="h-screen hidden xl:flex flex-col">
				<a href="" class="-intro-x flex items-center pt-5">

				</a>
				<div class="my-auto">
					<img
						class="-intro-x w-1/2 -mt-16"
						:src="require(`@/assets/images/login-illustration.svg`)"
					/>
					<div
						class="
							-intro-x
							text-white
							font-medium
							text-4xl
							leading-tight
							mt-10
						"
					>
						A few more clicks to <br />
						sign in to your account.
					</div>
					<div
						class="
							-intro-x
							mt-5
							text-lg text-white text-opacity-70
							dark:text-gray-500
						"
					>
						Manage all your e-commerce accounts in one place
					</div>
				</div>
			</div>
			<!-- END: Login Info -->

			<router-view />
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';

export default {
	name: 'AuthLayout',
	setup () {
		onMounted(() => {
			document.body.setAttribute('class', 'login max-h-screen');
		});

		onUnmounted(() => {
			document.body.removeAttribute('class', 'login max-h-screen');
		});
	}
};
</script>
